import widgetsHTTP from "@/api/widgets";

const state = {
  widgets: {
    company: {},
    product_extended: {},
  },
};
const mutations = {
  setWidgets(state, payload) {
    state.widgets = { ...payload };
  },
};
const actions = {
  async getWidgets({ commit }) {
    try {
      const res = await widgetsHTTP.getWidgets();
      if (Object.values(res.widgets).length) {
        commit("setWidgets", res.widgets);
      }
    } catch (e) {
      throw e;
    }
  },
  async postWidgets({ commit, state }, data) {
    try {
      if (!Object.keys(data).length) {
        data = [];
      }
      const res = await widgetsHTTP.postWidgets({ widgets: data });
      if (Object.values(res.widgets).length) {
        commit("setWidgets", res.widgets);
      }
    } catch (e) {
      throw e;
    }
  },
};
const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
