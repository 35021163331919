import Vue from "vue";
import Vuex from "vuex";

import Auth from "./modules/Auth";
import Widgets from "./modules/Widgets";
import Settings from "./modules/Settings";
import Logs from "./modules/Logs";
import Pages from "./modules/Pages";
Vue.use(Vuex);

export default new Vuex.Store({
  state: { loading: true },
  getters: {},
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
  },
  actions: {},
  modules: {
    Auth,
    Widgets,
    Settings,
    Logs,
    Pages,
  },
});
