<template>
  <v-app :class="changeTheme">
    <v-app-bar v-if="!isErrorPage" app>
      <NavBar />
      <ProgressLine />
    </v-app-bar>
    <v-main class="v-main-static-color">
      <v-container v-if="!isLoading" class="wrap mt-10">
        <div class="wrap">
          <transition name="fade" mode="out-in">
            <router-view> </router-view>
          </transition>
        </div>
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar/Index.vue";
import ProgressLine from "@/components/ProgressLine.vue";
import { mapActions } from "vuex";
import Footer from "@/components/Footer";

import { mapGetters } from "vuex";
export default {
  name: "App",
  components: { NavBar, ProgressLine, Footer },

  data: () => ({
    theme: false,
    isLoading: false,
  }),
  computed: {
    ...mapGetters("Auth", ["isAuth"]),
    isErrorPage() {
      return this.$route.name === "Not Found";
    },
    changeTheme() {
      return this.isErrorPage ? "error-theme" : "feedbackcompany-theme";
    },
  },
  async mounted() {
    try {
      this.isLoading = true;
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (!sessionStorage.getItem("lightspeed_token")) {
        if (this.$route.name !== "Not Found") {
          if (!params?.token && !params?.signature) {
            this.$router.push("/404");
          } else {
            await this.fetchAuth(params);
          }
        }
      }
      this.isLoading = false;
    } catch (e) {
      // this.$router.push("/404");
    }
  },
  methods: {
    ...mapActions("Auth", ["fetchAuth"]),
  },
};
</script>

<style lang="scss">
@import "@/assets/feedbackcompany.scss";

.wrap {
  max-width: 1100px;
  width: 100%;
  .wrap:only-child {
    margin: 0 auto;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
