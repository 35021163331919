<template>
  <v-tabs fixed-tabs show-arrows>
    <v-tab to="/">Authentication</v-tab>
    <v-tab :disabled="settings.disabled" to="/settings">
      Invitation settings
    </v-tab>
    <v-tab :disabled="widgets.disabled" to="/widgets">Widgets</v-tab>
    <v-tab :disabled="logs.disabled" to="/logs">Logs</v-tab>
  </v-tabs>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "NavBar",

  data: () => {
    return {};
  },
  computed: {
    ...mapState("Pages", ["settings", "widgets", "logs"]),
  },
};
</script>
