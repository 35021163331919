import Axios from "axios";

const baseURL = process.env.VUE_APP_BACKEND_URL;
const axios = Axios.create({ baseURL });

axios.interceptors.request.use(function (config) {
  const token = `Bearer ${sessionStorage.getItem("lightspeed_token")}`;
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    if (response.data.redirect) {
      window.location.replace(response.data.redirect);
    }
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // window.location.replace("/404");
    }
    return Promise.reject(error);
  }
);
export default axios;
