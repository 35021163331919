import axios from "@/plugins/axios";
const baseURL = process.env.VUE_APP_BACKEND_URL;

export default {
  async getAuth(params) {
    const url = baseURL + "auth";
    return (await axios.get(url, { params })).data;
  },
  async postCreditsUser(data) {
    return (await axios.post("/feedback-api/auth", data)).data;
  },
  async getCreditsUser() {
    return (
      await axios.get(
        "/feedback-api/auth"
        // "https://fc.interactivated.me:8443/api/v1/feedback-api/auth"
      )
    ).data;
  },
};
