<template>
  <v-progress-linear :active="loading" indeterminate absolute bottom />
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["loading"]),
  },
};
</script>
