import AuthHTTP from "@/api/auth";

const state = {
  client_id: "",
  client_secret: "",
  isDisabledNextPage: true,
};
const mutations = {
  setToken(state, token) {
    sessionStorage.setItem("lightspeed_token", token);
  },
  setClientId(state, client_id) {
    state.client_id = client_id;
  },
  setClientSecret(state, client_secret) {
    state.client_secret = client_secret;
  },
  setDisabledNextPage(state, bool) {
    state.isDisabledNextPage = bool;
  },
};
const actions = {
  fetchAuth: async ({ commit }, params) => {
    const response = await AuthHTTP.getAuth(params);
    if (!response.access_token) {
      throw "auth failed";
    }
    commit("setToken", response.access_token);
  },

  postCreditsUser: async ({ commit, state: { client_id, client_secret } }) => {
    const response = await AuthHTTP.postCreditsUser({
      client_id,
      client_secret,
    });
  },

  getCreditsUser: async ({ commit }) => {
    const response = await AuthHTTP.getCreditsUser();
    commit("setClientId", response.client_id);
    commit("setClientSecret", response.client_secret);
    return response;
  },
};
const getters = {
  isAuth(state) {
    return !!sessionStorage.getItem("lightspeed_token");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
